import { tw } from '@/utils/tw';

const Banner = tw.theme({
  slots: {
    base: '',
    backgroundImage: '',
    content: '',
  },
});

export default Banner;
