import { tw } from '@/utils/tw';
import RadioButtonTheme from 'base/components/RadioButton/theme';

const RadioButton = tw.theme({
  extend: RadioButtonTheme,
  slots: {
    input: [
      '[&:not(:checked)]:hover:shadow-yves-klein-200',
      'active:before:-z-1',
      'active:before:absolute',
      'active:before:bg-gray-100',
      'active:before:inset-0',
      'appearance-none',
      'bg-clip-content',
      'border-gray-900',
      'border',
      'checked:bg-gray-900',
      'checked:hover:bg-black',
      'checked:hover:shadow-none',
      'focus:border',
      'focus:outline-0',
      'focus:shadow-4',
      'focus:shadow-yves-klein-200',
      'h-5',
      'hover:border-1',
      'hover:border-black',
      'hover:shadow-4',
      'overflow-hidden',
      'p-1',
      'relative',
      'rounded-full',
      'w-5',
    ],
    label: ['-mb-[3px]', 'ml-2.5', '!font-secondary', '!text-base', 'leading-5', 'tracking-[0.3px]', 'capitalize'],
  },
});

export default RadioButton;
