import { tw } from '@/utils/tw';
import BaseAffiliateItemTheme from 'base/components/AffiliateItem/theme';

const { Image } = BaseAffiliateItemTheme;

const AffiliateItem = tw.theme({
  extend: BaseAffiliateItemTheme,
  slots: {
    base: `gap-1.5 border-b border-gray-300 pb-4 my-3 md:relative md:left-1/2 md:-translate-x-1/2 md:gap-1.5 lg:min-w-[824px]`,
    counter: `text-gabor-md md:text-gabor-md md:-mb-1`,
    headline: `mb-1 text-headline-md md:text-headline-md`,
    subtitle: `text-preview-sm md:text-preview-sm mb-1 md:mb-2`,
    description: `text-preview-lg md:text-preview-lg`,
    productVendor: `text-preview-sm md:text-preview-sm`,
    productHeadline: `text-paragraph-md md:text-paragraph-md`,
    productCaption: `text-paragraph-md md:text-paragraph-md font-bold no-underline`,
  },
  variants: {
    multiple: {
      false: {
        description: `md:px-10`,
        group: `gap-2 md:mb-1.5 md:gap-2`,
        product: `gap-1.5 md:gap-1.5`,
      },
      true: {
        base: `md:gap-y-3`,
        description: `md:max-w-bodytext`,
        group: `gap-2 md:gap-2`,
        productVendor: `md:mt-0.5`,
      },
    },
  },
});

export default Object.assign(AffiliateItem, { Image });
