import { useStableProps } from '@/hooks/useStable';
import { getUrl } from '@/utils/getUrl';
import { http } from '@/utils/http';
import { logger } from '@/utils/logger';
import { mergeOptions } from '@/utils/merge';
import { StandaloneNewsletterSignup } from 'base/components/NewsletterSignup';
import { NewsletterSignupInputCheckboxProps } from 'base/components/NewsletterSignup/NewsletterSignup.InputCheckbox';
import { standaloneNewsletterSignupDefaults } from 'base/components/NewsletterSignup/NewsletterSignup.constants';
import { isProduction } from 'lib/labrador/utils';
import { ChangeEvent, useMemo, useState } from 'react';
import { FieldValues, SubmitHandler } from 'react-hook-form';

const vinspecialFooter =
  'Genom att klicka på “prenumerera” intygar jag att jag är över 25 år. <a href="https://www.aller.se/integritetspolicy/" target="_blank">Läs mer om Aller Medias integritetspolicy</a>.';

const newsletterSignupUrl = getUrl(
  isProduction
    ? 'https://se-labrador-helper-services.labrador.allermedia.io/newsletter/signup'
    : 'https://se-labrador-helper-services-stage.labrador.allermedia.io/newsletter/signup',
);

const onSubmitValid: SubmitHandler<FieldValues> = async (data) => {
  if (!newsletterSignupUrl) {
    return logger.error('Could not create URL for newsletter signup');
  }

  const params = new URLSearchParams({
    u: '3',
    f: '3',
    s: '',
    c: '0',
    m: '0',
    act: 'sub',
    v: '2',
    'field[7]': 'TRUE',
    jsonp: 'true',
    firstname: data.firstname,
    lastname: data.lastname,
    email: data.email,
  });

  if (data.newsletter.includes('elle')) {
    params.set('field[11]', 'TRUE');
    params.append('tags', 'ELLE - Newsletter - Signup');
  }
  if (data.newsletter.includes('mat-och-vin')) {
    params.set('field[13]', 'TRUE');
    params.append('tags', 'ELLE Mat & Vin - Newsletter - Signup');
  }
  if (data.newsletter.includes('decoration')) {
    params.set('field[14]', 'TRUE');
    params.append('tags', 'ELLE Decoration - Newsletter - Signup');
  }
  if (data.newsletter.includes('vinspecial')) {
    params.set('field[110]', 'TRUE');
    params.set('field[111]', 'TRUE');
    params.append('tags', 'ELLE Vin - Newsletter - Signup');
  }
  if (data.newsletter.includes('horoskop')) {
    params.set('field[128]', 'TRUE');
    params.append('tags', 'ELLE Horoskop - Newsletter - Signup');
  }

  newsletterSignupUrl.search = params.toString();

  const response = await http.get(newsletterSignupUrl.href);

  if (response?.status === 200) {
    return response;
  }

  throw response;
};

const asExtendedCheckboxes = ([label, value, options]: (string | object)[]) =>
  ({
    $standalone: {
      label,
      name: 'newsletter',
      value,
      options,
    },
    rules: { required: '* Välj ett eller flera nyhetsbrev' },
  }) as NewsletterSignupInputCheckboxProps;

export const ElleStandaloneNewsletterSignup: typeof StandaloneNewsletterSignup = ({
  inputs,
  footer,
  options,
  ...props
}) => {
  const [isVinspecialChecked, setIsVinspecialChecked] = useState(false);

  const fields = standaloneNewsletterSignupDefaults.inputs?.fields;

  const checkboxes = useMemo(
    () =>
      [
        ['ELLE', 'elle'],
        ['ELLE Mat & Vin', 'mat-och-vin'],
        ['ELLE Decoration', 'decoration'],
        [
          'ELLE Vinspecial',
          'vinspecial',
          {
            $input: {
              onClick: (event: ChangeEvent<HTMLInputElement>) => {
                setIsVinspecialChecked(event.target.checked);
              },
            },
          },
        ],
        ['Veckans horoskop', 'horoskop'],
      ].map(asExtendedCheckboxes),
    [],
  );

  const stableProps = useStableProps(
    {
      inputs: {
        checkboxes: inputs?.checkboxes || checkboxes,
        fields: inputs?.fields || fields,
      },
      options: mergeOptions(options, {
        $button: { size: 'large' },
        onSubmitValid,
      }),
    },
    props,
  );

  return (
    <StandaloneNewsletterSignup
      footer={isVinspecialChecked ? vinspecialFooter : footer}
      headline="Få det bästa av ELLEs värld - anmäl dig till våra nyhetsbrev"
      description="Vi handplockar vårt bästa innehåll åt dig med maxad inspiration och de senaste trenderna. Utöver det får du ta del av exklusiva erbjudanden, tävlingar och inbjudningar till olika event. Fyll bara i dina uppgifter här så sköter vi resten! &#9829;"
      {...stableProps}
    />
  );
};
