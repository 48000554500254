/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "button_colors_primary": "",
  "button_colors_secondary": "bg-primary-200 hover:bg-primary-300 active:bg-primary-400 focus:ring-primary-300 border-transparent text-primary-700",
  "button_size_default": "!py-3",
  "button_variant_default": "",
  "description_colors_primary": "text-black",
  "description_colors_secondary": "",
  "description_size_default": "text-preview-lg",
  "description_variant_default": "",
  "fieldset_caption_colors_primary": "text-error-700",
  "fieldset_caption_colors_secondary": "",
  "fieldset_caption_size_default": "text-body-xs",
  "fieldset_caption_variant_default": "",
  "fieldset_colors_primary": "",
  "fieldset_colors_secondary": "",
  "fieldset_size_default": "gap-3",
  "fieldset_size_alternative": "gap-3 mt-3",
  "fieldset_variant_default": "grid grid-cols-2",
  "fieldset_variant_alternative": "flex flex-row flex-wrap",
  "footer_colors_primary": "text-black",
  "footer_colors_secondary": "text-white",
  "footer_size_default": "w-full text-preview-sm [&_a]:font-medium",
  "footer_variant_default": "text-center",
  "headline_colors_primary": "text-black",
  "headline_colors_secondary": "text-primary-700",
  "headline_size_default": "text-headline-sm md:text-headline-md",
  "headline_size_alternative": "",
  "headline_variant_default": "",
  "headline_variant_alternative": "",
  "inputCheckbox_input_colors_primary": "",
  "inputCheckbox_input_colors_secondary": "",
  "inputCheckbox_input_size_default": "",
  "inputCheckbox_input_variant_default": "",
  "inputCheckbox_label_colors_primary": "",
  "inputCheckbox_label_colors_secondary": "",
  "inputCheckbox_label_size_default": "!text-preview-lg",
  "inputCheckbox_label_variant_default": "",
  "inputCheckbox_colors_primary": "",
  "inputCheckbox_colors_secondary": "",
  "inputCheckbox_size_default": "",
  "inputCheckbox_variant_default": "",
  "inputField_caption_colors_primary": "",
  "inputField_caption_colors_secondary": "bg-error-600 text-white",
  "inputField_caption_size_default": "",
  "inputField_caption_size_alternative": "px-2.5 py-1.5 mt-1",
  "inputField_caption_variant_default": "",
  "inputField_group_colors_primary": "",
  "inputField_group_colors_secondary": "",
  "inputField_group_size_default": "",
  "inputField_group_size_alternative": "",
  "inputField_group_variant_default": "",
  "inputField_icon_colors_primary": "",
  "inputField_icon_colors_secondary": "",
  "inputField_icon_size_default": "",
  "inputField_icon_size_alternative": "",
  "inputField_icon_variant_default": "group-data-[status=default]:hidden",
  "inputField_input_colors_primary": "",
  "inputField_input_colors_secondary": "",
  "inputField_input_size_default": "",
  "inputField_input_size_alternative": "",
  "inputField_input_variant_default": "",
  "inputField_label_colors_primary": "text-gray-700",
  "inputField_label_colors_secondary": "",
  "inputField_label_size_default": "",
  "inputField_label_size_alternative": "",
  "inputField_label_variant_default": "",
  "inputField_colors_primary": "[&_svg]:shadow-white",
  "inputField_colors_secondary": "",
  "inputField_size_default": "data-[field=email]:mb-2.5",
  "inputField_size_alternative": "",
  "inputField_variant_default": "data-[field=email]:col-span-2 [&_svg]:shadow-[-8px_8px_10px]",
  "modal_content_colors_primary": "",
  "modal_content_colors_secondary": "",
  "modal_content_size_default": "text-preview-lg md:max-w-2/3 [&_h1]:mb-3 [&_h1]:text-headline-sm md:[&_h1]:text-headline-md [&_h2]:mb-3 [&_h2]:text-headline-sm md:[&_h2]:text-headline-md [&_h3]:mb-3 [&_h3]:text-headline-sm md:[&_h3]:text-headline-md [&_h4]:mb-3 [&_h4]:text-headline-sm md:[&_h4]:text-headline-md",
  "modal_content_variant_default": "",
  "modal_colors_primary": "bg-light-sand-500 outline-none",
  "modal_colors_secondary": "",
  "modal_size_default": "w-full max-w-article-lg px-3 py-6 sm:px-4.5 sm:py-9 md:px-6 md:py-12",
  "modal_variant_default": "hidden open:animate-fade-right open:animate-duration-500 open:backdrop:animate-fade open:flex flex-col items-center gap-y-3 rounded cursor-pointer backdrop:cursor-pointer text-center -top-1/4 md:-top-1/3",
  "rootError_colors_primary": "text-error-700",
  "rootError_colors_secondary": "",
  "rootError_size_default": "text-body-xs",
  "rootError_variant_default": "",
  "colors_primary": "bg-light-sand-500",
  "colors_secondary": "bg-[url(/image/newsletter-signup-bg.jpg)] bg-center bg-cover bg-no-repeat",
  "size_default": "w-full max-w-article-lg p-6 mx-auto",
  "variant_default": "flex flex-col gap-y-3 rounded"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "NewsletterSignup");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;