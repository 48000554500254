import { tw } from '@/utils/tw';
import BaseArticleTeaserBodytextTheme from 'base/components/ArticleTeaser/Bodytext/theme';

const { Image } = BaseArticleTeaserBodytextTheme;

const ArticleTeaserBodytext = tw.theme({
  extend: BaseArticleTeaserBodytextTheme,
  slots: {
    category: `hidden`,
    content: `max-w-[648px]`,
    description: `text-headline-2xs md:text-headline-sm`,
    footer: `hidden`,
    headline: `hidden`,
  },
  variants: {
    variant: {
      third: {
        content: `bg-light-sand-500 border-l-yves-klein-900 rounded-r-none`,
      },
    },
  },
  defaultVariants: {
    variant: 'third',
  },
});

export default Object.assign(ArticleTeaserBodytext, { Image });
