import { LabradorComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { LabradorJwVideoTeaser } from 'base/components/labrador/JwVideo/Teaser';

export const ElleLabradorJwVideoTeaser: LabradorComponent = (props) => {
  return (
    <LabradorJwVideoTeaser
      {...mergeProps(
        {
          data: {
            headline: props.data.brand ? `Videos från ${props.data.brand || 'ELLE'}` : '',
            options: {
              ...(props.data.isFrontPage
                ? {
                    colors: 'default',
                    $hint: {
                      colors: 'secondary',
                    },
                  }
                : {
                    colors: 'primary',
                    $headline: {
                      hideLine: true,
                    },
                    $icon: {
                      colors: 'default',
                    },
                    $content: {
                      colors: 'primary',
                    },
                  }),
            },
          },
        },
        props,
      )}
    />
  );
};
