import { ElleStandaloneNewsletterSignup } from '@/elle/components/NewsletterSignup';
import { useStable } from '@/hooks/useStable';
import { LabradorComponent } from '@/types/component';

export const ElleLabradorNewsletterSignup: LabradorComponent = ({ type, data, meta }) => {
  const stableProps = useStable({
    successModal: { content: data.successText },
    submitButton: { content: data.submitLabel },
  });

  return <ElleStandaloneNewsletterSignup footer={data.integrityText} {...stableProps} />;
};
