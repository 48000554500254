import { LabradorComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { LabradorArticleTeaserDefault } from 'base/components/labrador/ArticleTeaser/Default';

export const ElleLabradorArticleTeaserDefault: LabradorComponent = (props) => {
  return (
    <LabradorArticleTeaserDefault
      {...mergeProps(
        {
          data: {
            showSubtitle: {
              desktop: false,
              mobile: false,
            },
          },
        },
        props,
      )}
    />
  );
};
