import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';
import BaseArticleHeaderTheme from 'base/components/ArticleHeader/theme';

const Tag = tw.theme({
  extend: TagTheme,
  variants: {
    colors: {
      text: 'text-gray-800',
    },
    variant: {
      text: 'uppercase',
    },
  },
});

const ArticleHeader = tw.theme({
  extend: BaseArticleHeaderTheme,
  variants: {
    variant: {
      third: {
        base: `mx-auto mb-6 grid w-full max-w-content-lg grid-cols-[auto_auto_1fr] items-baseline gap-3`,
        bylines: `flex flex-col flex-wrap gap-0.5`,
        category: `order-4 col-span-3 -mb-1`,
        description: `order-5 col-span-3 max-w-[790px] text-preamble-md`,
        headline: `order-2 col-span-3 text-headline-md md:text-headline-xl`,
        media: `order-3 col-span-3 [&_figcaption]:flex-row [&_figcaption]:gap-1.5 [&_img]:aspect-default [&_img]:object-cover [&_picture]:!aspect-default [&_span]:mb-0`,
        tags: `order-1 col-span-3 -mb-2 flex gap-3`,
      },
    },
  },
  defaultVariants: {
    variant: 'third',
  },
});

export default Object.assign(ArticleHeader, { Tag });
