import { tw } from '@/utils/tw';
import BaseArticleDateTheme from 'base/components/ArticleDate/theme';

const ArticleDate = tw.theme({
  extend: BaseArticleDateTheme,
  slots: {
    date: `text-preview-sm text-gray-800`,
    updatedDate: `text-preview-sm text-gray-800`,
  },
});

export default ArticleDate;
