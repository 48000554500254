/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneBanner as ResolvedStandaloneBanner } from 'base/components/standalone/Banner';

export const Banner: typeof ResolvedStandaloneBanner = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneBanner {...props} />
        </ErrorBoundary>
    );
});

export type BannerProps = PropsFromComponent<typeof Banner>;

/** @deprecated Use slot architecture instead */
export const BannerWith = (extras: DynamicStandaloneExtras): typeof Banner => {
    return function Banner(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Banner');
        return <ResolvedStandaloneBanner {...mergeProps({ options: { theme } }, props)} />;
    }
}