import { tw } from '@/utils/tw';
import BaseArticleFooterTheme from 'base/components/ArticleFooter/theme';

const Tag = tw.theme({
  extend: BaseArticleFooterTheme.Tag,
});

const ArticleFooter = tw.theme({
  extend: BaseArticleFooterTheme,
  slots: {
    base: `mt-8 w-full gap-4`,
    line: `hidden`,
  },
  defaultVariants: {
    center: true,
  },
});

export default Object.assign(ArticleFooter, { Tag });
