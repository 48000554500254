/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "link": {
    "colors_default": "text-black",
    "size_default": "ml-6 md:ml-0",
    "variant_default": "no-underline block"
  },
  "image": {
    "group_size_default": "",
    "colors_default": "bg-transparent",
    "size_default": "",
    "variant_default": "data-[fill]:aspect-square not-prose relative block object-cover"
  },
  "caption_colors_default": "text-black",
  "caption_size_default": "text-preview-lg pt-2",
  "caption_variant_default": "",
  "colors_default": "",
  "size_default": "",
  "variant_default": "text-center"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "SliderSlide");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;