import { tw } from '@/utils/tw';
import DialogTheme from 'base/components/Dialog/theme';

const CloseIcon = tw.theme({
  extend: DialogTheme.CloseIcon,
  slots: {
    base: ['fill-white', 'mx-auto'],
  },
});

const DialogIcon = tw.theme({
  extend: DialogTheme.DialogIcon,
});

const Dialog = tw.theme({
  extend: DialogTheme,
  slots: {
    base: ['backdrop:bg-black/60', 'bg-light-sand-500'],
    buttonGroup: ['[&>button]:max-w-none'],
  },
});

export default Object.assign(Dialog, { DialogIcon, CloseIcon });
