import { mergeProps } from '@/utils/mergeProps';
import { StandaloneBlogSlider } from 'base/components/BlogSlider';

export const ElleStandaloneBlogSlider: typeof StandaloneBlogSlider = (props) => {
  return (
    <StandaloneBlogSlider
      {...mergeProps(
        {
          options: {
            $slide: {
              $image: {
                width: '200',
                height: '100',
              },
            },
            $swiper: {
              autoHeight: true,
            },
          },
        },
        props,
      )}
    />
  );
};
