import { Icon } from '@/components/Icon';
import { Link } from '@/components/Link';
import NotAMemberModalImage from '@/elle/public/image/not-a-member-image.png';
import useUserAuth from '@/hooks/useUserAuth';
import { StandaloneSaveArticleButton } from 'base/components/SaveArticleButton';
import NextImage from 'next/image';
import { useMemo } from 'react';

export const ElleStandaloneSaveArticleButton: typeof StandaloneSaveArticleButton = (props) => {
  const { logInUrl, signUpUrl } = useUserAuth();

  const memoModalContent = useMemo(
    () => (
      <div className="mx-auto flex w-fit flex-col-reverse md:flex-row">
        <NextImage src={NotAMemberModalImage} alt="" className="relative h-62 w-74" />
        <div className="flex flex-col bg-white p-4 text-center">
          <Icon options={{ size: 'medium', className: 'cursor-pointer self-end hover:text-gray-600' }} name="close" />
          <span className="p-4 text-headline-2xs">
            För att spara den här artikeln
            <br /> måste du vara medlem
          </span>
          <Link
            href={logInUrl}
            content="Logga in på ditt konto"
            options={{ className: 'text-preamble-md px-4 hover:underline', rel: 'nofollow' }}
          />
          <span className="text-preview-sm p-4">eller</span>
          <Link
            href={signUpUrl}
            content="Skapa ett konto här!"
            options={{ className: 'text-preamble-md px-4 hover:underline', rel: 'nofollow' }}
          />
        </div>
      </div>
    ),
    [logInUrl, signUpUrl],
  );

  return <StandaloneSaveArticleButton modalContent={memoModalContent} {...props} />;
};
