/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "button": {
    "extend": {
      "size_large": "my-4"
    }
  },
  "login_caption_colors_default": "",
  "login_caption_size_default": "text-preview-sm",
  "login_caption_variant_default": "",
  "login_footer_colors_default": "hover:text-yves-klein-900",
  "login_footer_size_default": "text-button-md",
  "login_footer_variant_default": "hover:underline",
  "login_headline_colors_default": "",
  "login_headline_size_default": "text-gabor-lg",
  "login_headline_variant_default": "",
  "login_colors_default": "",
  "login_size_default": "gap-y-2",
  "login_variant_default": "flex flex-col items-center text-center",
  "purchase_colors_default": "",
  "purchase_size_default": "",
  "purchase_variant_default": "",
  "colors_default": "before:bg-gradient-to-t before:from-white",
  "size_default": "w-full py-9 before:w-full before:h-69 lg:min-w-[824px]",
  "variant_default": "relative before:absolute before:left-0 before:bottom-full not-prose md:left-1/2 md:-translate-x-1/2"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "ContentLock");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;