import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';
import BaseTagsIndexTheme from 'base/components/TagsIndex/theme';

const Tag = tw.theme({
  extend: TagTheme,
  base: ``,
  variants: {
    size: {
      text: 'text-paragraph-md',
    },
    colors: {
      text: ``,
    },
    variant: {
      text: ``,
    },
  },
  defaultVariants: {
    variant: 'text',
    size: 'text',
    colors: 'text',
  },
});

const TagsIndex = tw.theme({
  extend: BaseTagsIndexTheme,
  slots: {
    base: ``,
    headline: `mb-6 text-headline-sm md:text-headline-lg`,
    group: ``,
    letter: `!text-gabor-md font-medium`,
    tags: `gap-x-4`,
  },
});

export default Object.assign(TagsIndex, { Tag });
