/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { ElleStandaloneMemberOffers as ResolvedStandaloneMemberOffers } from 'sites/elle/components/standalone/MemberOffers';

export const MemberOffers: typeof ResolvedStandaloneMemberOffers = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneMemberOffers {...props} />
        </ErrorBoundary>
    );
});

export type MemberOffersProps = PropsFromComponent<typeof MemberOffers>;

/** @deprecated Use slot architecture instead */
export const MemberOffersWith = (extras: DynamicStandaloneExtras): typeof MemberOffers => {
    return function MemberOffers(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'MemberOffers');
        return <ResolvedStandaloneMemberOffers {...mergeProps({ options: { theme } }, props)} />;
    }
}