/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "slide": {
    "caption_colors_default": "text-black"
  },
  "swiper": {
    "arrow_colors_default": "text-black",
    "navigation_item_colors_default": "bg-primary-600 data-[active=true]:bg-primary-200",
    "title_colors_default": "",
    "title_size_default": "text-gabor-sm md:text-gabor-lg !leading-6 md:!leading-8 pb-3 md:pb-6 pl-6 md:pl-0",
    "title_variant_default": "text-center"
  },
  "colors_default": "bg-light-sand-500 wings-light-sand-500",
  "size_default": "pb-4 md:pb-6 pt-3 md:pt-6 md:px-3 w-screen -ml-[calc(50vw-50%)] md:w-full md:ml-0",
  "variant_default": "relative wings"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "BlogSlider");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;