/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { ElleStandaloneAuthorsList as ResolvedStandaloneAuthorsList } from 'sites/elle/components/standalone/AuthorsList';

export const AuthorsList: typeof ResolvedStandaloneAuthorsList = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneAuthorsList {...props} />
        </ErrorBoundary>
    );
});

export type AuthorsListProps = PropsFromComponent<typeof AuthorsList>;

/** @deprecated Use slot architecture instead */
export const AuthorsListWith = (extras: DynamicStandaloneExtras): typeof AuthorsList => {
    return function AuthorsList(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'AuthorsList');
        return <ResolvedStandaloneAuthorsList {...mergeProps({ options: { theme } }, props)} />;
    }
}