import { logger } from '@/utils/logger';

export const parseJSON = (input: string, fallback: unknown = null) => {
  try {
    return JSON.parse(input);
  } catch (err) {
    logger.error(err);
    return fallback;
  }
};
