/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneConfirmation as ResolvedStandaloneConfirmation } from 'base/components/standalone/Confirmation';

export const Confirmation: typeof ResolvedStandaloneConfirmation = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneConfirmation {...props} />
        </ErrorBoundary>
    );
});

export type ConfirmationProps = PropsFromComponent<typeof Confirmation>;

/** @deprecated Use slot architecture instead */
export const ConfirmationWith = (extras: DynamicStandaloneExtras): typeof Confirmation => {
    return function Confirmation(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Confirmation');
        return <ResolvedStandaloneConfirmation {...mergeProps({ options: { theme } }, props)} />;
    }
}