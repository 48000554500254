/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { ElleStandaloneBlogSlider as ResolvedStandaloneBlogSlider } from 'sites/elle/components/standalone/BlogSlider';

export const BlogSlider: typeof ResolvedStandaloneBlogSlider = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneBlogSlider {...props} />
        </ErrorBoundary>
    );
});

export type BlogSliderProps = PropsFromComponent<typeof BlogSlider>;

/** @deprecated Use slot architecture instead */
export const BlogSliderWith = (extras: DynamicStandaloneExtras): typeof BlogSlider => {
    return function BlogSlider(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'BlogSlider');
        return <ResolvedStandaloneBlogSlider {...mergeProps({ options: { theme } }, props)} />;
    }
}