import { StandaloneAuthorsList } from 'base/components/AuthorsList';

export const ElleStandaloneAuthorsList: typeof StandaloneAuthorsList = ({ authors, ...props }) => {
  const mappedAuthors = authors?.map((author) => ({
    ...author,
    title: `${author.title} - Elle`,
  }));

  return <StandaloneAuthorsList authors={mappedAuthors} {...props} />;
};
