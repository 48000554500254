import { ComponentProps, ComponentWithRef } from '@/types/component';
import React from 'react';

export type MemberOffersModalProps = ComponentProps<'dialog'>;

export const MemberOffersModal: ComponentWithRef<HTMLDialogElement, MemberOffersModalProps> = React.forwardRef(
  ({ children, ...props }, ref) => {
    return (
      <dialog ref={ref} {...props}>
        {children}
      </dialog>
    );
  }
);
