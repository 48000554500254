/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "slide": {
    "image": {
      "size_default": "min-w-25 sm:min-w-37.5"
    }
  },
  "swiper": {},
  "disclaimer_line_variant_default": "md:text-center border-b",
  "disclaimer_line_colors_default": "border-gray-500",
  "disclaimer_line_size_default": "mb-4 md:mb-9 md:mx-12 h-3.5",
  "disclaimer_colors_default": "text-gray-800 bg-white",
  "disclaimer_size_default": "text-preview-sm pl-6 pr-3 md:px-3",
  "disclaimer_variant_default": "",
  "title_variant_default": "",
  "title_colors_default": "",
  "title_size_default": "text-preview-sm mt-1",
  "price_variant_default": "font-semibold",
  "price_colors_default": "",
  "price_size_default": "text-preview-sm",
  "colors_default": "",
  "size_default": "w-screen ml-[-50vw] md:w-full md:ml-0 data-[page-type=article]:px-6",
  "variant_default": "relative left-1/2 md:left-0"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "AffiliateGallery");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;