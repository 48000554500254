/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { ElleLabradorArticleHeader as ResolvedLabradorArticleHeader } from 'sites/elle/components/labrador/ArticleHeader';

export const LabradorArticleHeader: typeof ResolvedLabradorArticleHeader = withLabradorInstanceof((props) => {
    return <ResolvedLabradorArticleHeader {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorArticleHeaderProps = PropsFromComponent<typeof LabradorArticleHeader>;
