/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneFront as ResolvedStandaloneFront } from 'base/components/standalone/Front';

export const Front: typeof ResolvedStandaloneFront = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneFront {...props} />
        </ErrorBoundary>
    );
});

export type FrontProps = PropsFromComponent<typeof Front>;

/** @deprecated Use slot architecture instead */
export const FrontWith = (extras: DynamicStandaloneExtras): typeof Front => {
    return function Front(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Front');
        return <ResolvedStandaloneFront {...mergeProps({ options: { theme } }, props)} />;
    }
}