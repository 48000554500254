import { Icon, IconProps } from '@/components/Icon';
import { Link } from '@/components/Link';
import { Component } from '@/types/component';
import { logger } from '@/utils/logger';
import NextImage from 'next/image';
import { useState } from 'react';
import { Offer, placeholderImage } from '.';

export interface MemberOffersModalContentProps {
  activeOffer: Offer;
  onCloseModal: () => void;
}

interface Tooltip {
  text?: string;
  icon?: IconProps['name'];
}

export const MemberOffersModalContent: Component<MemberOffersModalContentProps> = ({ activeOffer, onCloseModal }) => {
  const [tooltip, setTooltip] = useState<Tooltip | null>(null);

  const { img, offerCode, offerFooterText, offerLink, offerLinkText } = activeOffer;

  const handleCopyCode = async () => {
    try {
      await navigator.clipboard.writeText(offerCode);
      setTooltip({ text: 'Kopierad!', icon: 'done' });

      // Show "Copied" message to user for two seconds
      setTimeout(() => setTooltip(null), 2000);
    } catch (error: any) {
      logger.catch(`Member offers failed to copy code: "${error.message}"`);

      setTooltip({ text: 'Kopieringsfel!', icon: 'close' });
      setTimeout(() => setTooltip(null), 2000);

      return null;
    }
  };

  return (
    <div className="text-preview-lg relative mx-auto flex w-full max-w-96 flex-col items-center bg-white px-4 py-10 text-center">
      <Icon
        name="close"
        options={{ size: 'medium', className: 'absolute top-0 right-0 m-2 cursor-pointer', onClick: onCloseModal }}
      />
      <div className="relative w-full">
        <NextImage src={img || placeholderImage} alt="" width={308} height={233} className="mx-auto" />
      </div>
      {offerCode && (
        <div className="my-6 flex flex-row items-center bg-gray-200 px-6 py-2">
          <span className="mr-4">Din kod:</span>
          <span className="mr-4 font-medium">{offerCode}</span>
          <button className="focus:none relative cursor-pointer" onClick={handleCopyCode}>
            <Icon name="copy" options={{ size: 'medium' }} />
            <div
              className={`duration-300', text-label-md absolute -top-9 left-1/4 flex -translate-x-1/4 transform flex-row border bg-white px-3 py-1 shadow-md transition-opacity ${tooltip ? 'opacity-100' : 'opacity-0'} ${tooltip ? 'visible' : 'invisible'} `}
            >
              <Icon name={tooltip?.icon} options={{ className: 'mr-1', size: 'medium' }} />
              <span className="mt-2">{tooltip?.text}</span>
            </div>
          </button>
        </div>
      )}
      {offerLink && (
        <Link
          href={offerLink}
          target="_blank"
          options={{
            rel: 'nofollow',
            className: 'flex flex-row items-center mb-4 font-medium',
          }}
          content={
            <>
              {offerLinkText || 'Gå till event'}
              <Icon name="chevronRight" options={{ size: 'medium' }} />
            </>
          }
        />
      )}
      {offerFooterText && <p className="text-preview-sm">{offerFooterText}</p>}
    </div>
  );
};
