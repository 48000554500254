/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { ElleLabradorJwVideoTeaser as ResolvedLabradorJwVideoTeaser } from 'sites/elle/components/labrador/JwVideo/Teaser';

export const LabradorJwVideoTeaser: typeof ResolvedLabradorJwVideoTeaser = withLabradorInstanceof((props) => {
    return <ResolvedLabradorJwVideoTeaser {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorJwVideoTeaserProps = PropsFromComponent<typeof LabradorJwVideoTeaser>;
