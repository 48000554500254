import { tw } from '@/utils/tw';
import { default as BaseShowHero } from 'base/components/ShowHero/theme';

const { Tag } = BaseShowHero;

const ShowHero = tw.theme({
  extend: BaseShowHero,
  slots: {
    base: 'to-light-sand-500 from-white',
    content: `gap-4`,
    headline: 'text-headline-sm md:text-headline-lg',
    description: 'text-preview-sm md:text-preview-lg',
  },
});

export default Object.assign(ShowHero, { Tag });
